import React, { useState } from 'react';
import { Grid2 } from '@mui/material';
import EmptyEnvelope from './emptyEnvelope';
import Invitation from './invitation';
import ReservationInfo from '../rsvpModule/ReservationInfo';
import EnvelopeAnimation from './envelopeAnimation';

const WeddingPage = () => {
  const [componentToDisplay, setComponentToDisplay] = useState(0);

  const Display = (component) => {
    switch (component) {
      case 2:
        return <Invitation setComponentToDisplay={setComponentToDisplay} />;

      case 3:
        return <ReservationInfo setComponentToDisplay={setComponentToDisplay} />;

      default:
        // return <EnvelopeAnimation setComponentToDisplay={setComponentToDisplay} />;
        return <EmptyEnvelope setComponentToDisplay={setComponentToDisplay} />;
    }
  };

  return (
    <Grid2 container>
      <Grid2 item size={12}>
        {Display(componentToDisplay)}
      </Grid2>
    </Grid2>
  );
};

export default WeddingPage;
