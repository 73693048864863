import axios from 'axios';

// Get guest information
const getAllGuest = async (eventId) => {
  try {
    const data = JSON.stringify({
      query: `query{
                guestInfoByEvent( eventId: "${eventId}"){
                    _id,
                    firstName,
                    lastName,
                    phoneNumber,
                    isAttending,
                    canHavePlusOne,
                    hasPlusOne,
                    eventId,
                    hasResponded,
                    willReceiveUpdate
                    isDeleted,
                }
            }`,
      variables: {},
    });

    const config = {
      method: 'post',
      // url: `/graphql/graphql`,
      url: `/graphql`,
      headers: {
        // Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config).then((response) => {
      if (response?.data?.errors?.length > 0) {
        return {};
      }
      return response.data.data.guestInfoByEvent;
    });
  } catch (error) {
    console.log('Error getting user info: ', error);
  }
};

export { getAllGuest };
