import React from 'react';
import { Box, Container, Typography, Grid2, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import coupleImage from '../../images/arby.png'; // Import the couple image
import backgroundImage from '../../images/Ndop_light.png'; // Import the couple image
import invitationPicture from '../../images/invitationPicture.png';
import { useRsvpContext } from '../../rsvpContext';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#a8e2ff',
    padding: '20px',
    // backgroundImage: `url(${backgroundImage})`,
  },
  invitation: {
    // backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    padding: '1px',
    textAlign: 'center',
    position: 'relative',
    color: '#335E80',
    // backgroundImage: `url(${backgroundImage})`,
  },
  title: {
    fontFamily: 'Great Vibes, cursive',
    fontSize: '3rem',
    color: '#2c3e50',
  },
  guestName: {
    fontFamily: 'Cursive',
    fontSize: '28px',
    marginBottom: '20px',
    color: '#34495e',
  },
  subtitle: {
    fontFamily: 'Cursive',
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: '#34495e',
  },
  eventDetails: {
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#9B4940',
    border: '5px solid #D6AA4B',
    color: 'white',
    borderRadius: '5px',
    width: '90%',
  },
  eventDetailsTitle: {
    fontSize: '16px',
    letterSpacing: '1px',
  },
  eventDetailsText: {
    fontSize: '26px',
    fontWeight: 'bold',
    color: ' #D6AA4B',
    letterSpacing: '2px',
  },
  note: {
    fontFamily: 'Cursive',
    fontSize: '1rem',
    color: '#7f8c8d',
    marginTop: '20px',
  },
  thinImage: {
    position: 'absolute',
    bottom: '40px',
    right: '-10px',
    clipPath: 'inset(30% 10% 0% 75%)', // Crop the image from top, right, bottom, and left
    width: '100%',
    height: '130%', // Set a specific height for the thin image
    zIndex: 1, // Make sure it is behind the couple image
  },
  coupleImage: {
    position: 'absolute',
    top: '20px',
    left: '65%',
    // right: '10px',
    objectFit: 'cover', // Fit the image content
    objectPosition: 'center', // Center the image cropping
    overflow: 'hidden', // Hide overflowing parts
    width: '40%',
    clipPath: 'inset(0 0 26% 0)', // Crop the image to show only the top 50%
    height: '130%', // Set a specific height
    zIndex: 2, // Make sure it is behind the couple image
  },
  buttonContainer: {
    marginTop: '10px',
  },
  button: {
    backgroundColor: '#9B4940',
    color: '#D6AA4B',
  },
}));

const Invitation = ({ setComponentToDisplay }) => {
  const classes = useStyles();

  const { guestFirstName, guestLastName } = useRsvpContext();

  return (
    <Box className={classes.container}>
      <Grid2 className={classes.invitation} container>
        <Box
          component="img"
          src={invitationPicture}
          alt="Envelope"
          sx={{ width: '100%', maxWidth: '800px', height: 'auto' }}
        />
        {/* <Grid2 item size={9}>
          <Typography className={classes.guestName}>
            To {guestFirstName} &nbsp; {guestLastName}
          </Typography>
          <Typography className={classes.subtitle}>
            Il était une fois, dans l’ouest du Cameroun, 2 âmes sœurs nommées Armelle Njinkeu et Roby Nkwamo. Sous le
            regard bienveillant de leurs ancêtres, ils se réuniront pour célébrer leur union entourés de leur famille et
            amis.
          </Typography>
          <Typography className={classes.subtitle}>Vous etes invités a célebrer le mariage de</Typography>
          <Typography className={classes.title}>Armelle & Roby</Typography>
          <Grid2 container spacing={3} className={classes.eventDetails} justifyContent={'center'}>
            <Grid2 item size={4}>
              <Typography className={classes.eventDetailsTitle}>LIEU</Typography>
              <Typography className={classes.eventDetailsText}>Kotto Chefferie</Typography>
            </Grid2>
            <Grid2 item size={4}>
              <Typography className={classes.eventDetailsTitle}>DATE</Typography>
              <Typography className={classes.eventDetailsText}>03 JAN 2025</Typography>
            </Grid2>
            <Grid2 item size={4}>
              <Typography className={classes.eventDetailsTitle}>HEURE</Typography>
              <Typography className={classes.eventDetailsText}>17H00</Typography>
            </Grid2>
          </Grid2>
          <Typography className={classes.note}>
            Note Importante: Pour une fête intime, merci de ne pas inviter de personnes supplémentaires.
          </Typography>
        </Grid2>
        <Grid2 item size={3}>
          <Box component="img" src={thinnerBackgroundImage} alt="Thin Image" className={classes.thinImage} />
          <Box component="img" src={coupleImage} alt="Couple" className={classes.coupleImage} />
        </Grid2> */}
      </Grid2>
      <Grid2 container className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          style={{ backgroundColor: '#0077b2' }}
          onClick={() => setComponentToDisplay(3)}
        >
          RSVP NOW
        </Button>
      </Grid2>
    </Box>
  );
};

export default Invitation;
