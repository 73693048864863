import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid2 } from '@mui/material';

export default function Header() {
  return (
    <Grid2
      container
      size={12}
      // rowSpacing={1}
      // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        backgroundColor: '#0C2D48',
      }}
    >
      <Grid2 item size={12}>
        <Typography
          align={'center'}
          color={'#fff'}
          variant="h1"
          gutterBottom
          fontSize={42}
          letterSpacing={2.5}
          fontWeight={500}
          fontFamily={'Roboto'}
        >
          THE NKWAMO'S
        </Typography>
      </Grid2>
    </Grid2>
  );
}
