import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Modal, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { getEventInfo, getGuestInfo } from '../../APIs/reservationApis';
import { useRsvpContext } from '../../rsvpContext';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '20px',
    textAlign: 'center',
  },
  inputField: {
    flex: 1,
    marginBottom: '50px',
  },
  submitButton: {
    marginTop: '20px',
  },
}));

const VerificationModal = ({ open, setOpen }) => {
  const { eventId, setGuestFirstName, setGuestLastName, setGuestPhoneNumber, setEventId } = useRsvpContext();
  const classes = useStyles();
  const { weddingSlug } = useParams();

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const eventInfo = async () => {
    await getEventInfo(weddingSlug).then((res) => {
      if (res?._id) setEventId(res._id);
    });
  };

  const getLocalGuest = () => {
    const localGuest = JSON.parse(localStorage.getItem('guest'));
    if (localGuest?.firstName || localGuest?.lastName || localGuest?.phoneNumber) {
      setFormData(localGuest);
    }
  };

  const handleSubmit = async () => {
    await getGuestInfo(formData.phoneNumber, formData.firstName, eventId).then((res) => {
      if (res?.isDeleted === false) {
        setGuestFirstName(res.firstName);
        setGuestLastName(res.lastName);
        setGuestPhoneNumber(res.phoneNumber);
        setOpen(false);
      }
    });
  };

  useEffect(() => {
    if (formData.firstName || formData.lastName || formData.phoneNumber) {
      localStorage.setItem('guest', JSON.stringify(formData));
    }
  }, [formData]);

  useEffect(() => {
    eventInfo();
    getLocalGuest();
  }, []);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        slots={{
          backdrop: Backdrop,
        }}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: 'blur(50px)', // Apply blur effect to the background
            },
          },
        }}
      >
        <Box className={classes.modalContainer}>
          <Typography variant="h6" mb={2}>
            Please Help Us Find You
          </Typography>
          <TextField
            className={classes.inputField}
            fullWidth
            label="First Name"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          {/* <TextField
            className={classes.inputField}
            fullWidth
            label="Last Name"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          /> */}
          <TextField
            className={classes.inputField}
            fullWidth
            label="ex: +18324629808"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.submitButton}
            disabled={formData.phoneNumber.length < 8 || formData.firstName.length < 1}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default VerificationModal;
