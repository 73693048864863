import React, { useState } from 'react';
import './envelopeAnimation.css'; // Make sure to import your CSS file

const EnvelopeAnimation = ({ setComponentToDisplay }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openEnvelope = () => {
    setIsOpen(true);
    setTimeout(() => {
      setComponentToDisplay(2);
    }, 4000);
  };

  const closeEnvelope = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className="envelope-wrapper">
        <div id="envelope" className={isOpen ? 'open' : 'close'} onClick={openEnvelope}>
          <div className="front flap"></div>
          <div className="front pocket"></div>
          <div className="letter">
            <div className="words line1"></div>
            <div className="words line2"></div>
            <div className="words line3"></div>
            <div className="words line4"></div>
          </div>
          <div className="hearts">
            <div className="heart a1"></div>
            <div className="heart a2"></div>
            <div className="heart a3"></div>
          </div>
        </div>
      </div>
      <div className="reset">
        <button id="open" onClick={openEnvelope}>
          Open
        </button>
        {/* <button id="reset" onClick={closeEnvelope}>
          Reset
        </button> */}
      </div>
    </div>
  );
};

export default EnvelopeAnimation;
