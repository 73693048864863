import React, { useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import VerificationModal from './verificationModal';
import EnvelopeAnimation from './envelopeAnimation';

const EmptyEnvelope = ({ setComponentToDisplay }) => {
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
      direction={isSmallScreen ? 'column' : 'row'}
    >
      <VerificationModal open={open} setOpen={setOpen} />
      <EnvelopeAnimation setComponentToDisplay={setComponentToDisplay} />
    </Box>
  );
};

export default EmptyEnvelope;
