import axios from 'axios';

// Get guest information
const getUserLogin = async (userName, password, eventId) => {
  try {
    const data = JSON.stringify({
      query: `query{
        userFind(userName: "${userName}", password: "${password}", eventId: "${eventId}") 
          {
            _id,
            firstName,
            lastName,
            phoneNumber,
            email,
            userName,
            isActive,
            eventId,
            role,
          }
        }`,
      variables: {},
    });

    const config = {
      method: 'post',
      // url: `/graphql/graphql`,
      url: `/graphql`,
      headers: {
        // Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config).then((response) => {
      if (response?.data?.errors?.length > 0) {
        return {};
      }
      return response.data.data.userFind;
    });
  } catch (error) {
    console.log('Error getting user info: ', error);
  }
};

export { getUserLogin };
