import axios from 'axios';

// Get guest information
const getGuestInfo = async (phoneNumber, firstName, eventId) => {
  try {
    const data = JSON.stringify({
      query: `query{
        guestInfoById(phoneNumber: "${phoneNumber}", firstName: "${firstName}", eventId: "${eventId}") 
          {
            _id,
            firstName,
            lastName,
            phoneNumber,
            isAttending,
            canHavePlusOne,
            hasPlusOne,
            eventId,
            isDeleted,
          }
        }`,
      variables: {},
    });

    const config = {
      method: 'post',
      // url: `/graphql/graphql`,
      url: `/graphql`,
      headers: {
        // Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config).then((response) => {
      if (response?.data?.errors?.length > 0) {
        return {};
      }
      return response.data.data.guestInfoById;
    });
  } catch (error) {
    console.log('Error getting guest info: ', error);
  }
};

const guestUpdate = async (phoneNumber, eventId, firstName, isAttending, hasPlusOne) => {
  try {
    let updatedFields = { isAttending, hasPlusOne };

    const data = JSON.stringify({
      query: `mutation ($updatedFields: [JSON]!) {
        guestUpdateById(
          phoneNumber: "${phoneNumber}",
          eventId: "${eventId}",
          firstName: "${firstName}",
          updatedField: $updatedFields,
        )
      }`,
      variables: {
        updatedFields,
      },
    });

    const config = {
      method: 'post',
      // url: `/graphql/graphql`,
      url: `/graphql`,
      headers: {
        // Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config).then((response) => {
      // console.log({ response });
      if (response?.data?.errors?.length > 0) {
        return false;
      }
      return response.data.data.guestUpdateById;
    });
  } catch (error) {
    console.log('Error getting guest info: ', error);
  }
};

const getEventInfo = async (slug) => {
  try {
    const data = JSON.stringify({
      query: `query{
        eventInfoBySlug(slug: "${slug}") 
          {_id,
            eventName,
            description,
            slug,
            email,
            eventDate,
            isDeleted,
            eventLocation{
                addressLine1
                addressLine2
                city
                state
                country
                zipCode
            },
            eventDate,
            isDeleted,
          }
        }`,
      variables: {},
    });

    const config = {
      method: 'post',
      // url: `/graphql/graphql`,
      url: `/graphql`,
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    };

    return await axios(config).then((response) => {
      console.log({ response });
      if (response?.data?.errors?.length > 0) {
        return {};
      }
      return response.data.data.eventInfoBySlug;
    });
  } catch (error) {
    console.log('Error getting guest info: ', error);
  }
};

export { getGuestInfo, guestUpdate, getEventInfo };
