import React, { createContext, useContext, useState } from 'react';

// Create the context
const RsvpContext = createContext();

// Create a provider component
export const RsvpProvider = ({ children }) => {
  const [guestPhoneNumber, setGuestPhoneNumber] = useState('');
  const [guestFirstName, setGuestFirstName] = useState('');
  const [guestLastName, setGuestLastName] = useState('');
  const [eventId, setEventId] = useState('');

  return (
    <RsvpContext.Provider
      value={{
        guestFirstName,
        guestLastName,
        eventId,
        guestPhoneNumber,
        setGuestPhoneNumber,
        setEventId,
        setGuestLastName,
        setGuestFirstName,
      }}
    >
      {children}
    </RsvpContext.Provider>
  );
};

// Create a custom hook to use the RsvpContext
export const useRsvpContext = () => {
  const context = useContext(RsvpContext);
  if (!context) {
    throw new Error('useRsvpContext must be used within a RsvpProvider');
  }
  return context;
};
