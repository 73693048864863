import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';

const components = ['LOGIN', 'DASHBOARD'];

const EventAdmin = () => {
  const { eventId } = useParams();

  const [selectedComponent, setSelectedComponent] = useState(components[0]);

  const componentToDisplay = () => {
    switch (selectedComponent) {
      case components[0]:
        return <Login setSelectedComponent={setSelectedComponent} eventId={eventId} />;
      case components[1]:
        return <Dashboard setSelectedComponent={setSelectedComponent} eventId={eventId} />;

      default:
        break;
    }
  };

  return <div>{componentToDisplay()}</div>;
};

export default EventAdmin;
