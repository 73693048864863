import React, { useState } from 'react';
import { TextField, Button, Grid2, Typography, Container } from '@mui/material';
import { getUserLogin } from '../../../APIs/userApis';
import { useEffect } from 'react';

const Login = ({ setSelectedComponent, eventId }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!username || !password) {
      setError('Both fields are required.');
      return;
    }

    await getUserLogin(username, password, eventId).then((res) => {
      let logIn = {};
      if (res?._id) {
        logIn = { state: true };
        setSelectedComponent('DASHBOARD');
      } else {
        logIn = { state: false };
        setError('Invalid username and/or password');
      }
      localStorage.setItem('logIn', JSON.stringify(logIn));
    });
  };

  useEffect(() => {
    const logIn = JSON.parse(localStorage.getItem('logIn'));

    if (logIn?.state === true) {
      setSelectedComponent('DASHBOARD');
    }
  }, []);

  return (
    <Container maxWidth="xs" sx={{ marginTop: '5rem' }}>
      <Typography variant="h5" gutterBottom>
        Login
      </Typography>
      {error && (
        <Typography color="error" variant="body2" align="center">
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit}>
        <Grid2 container spacing={2} direction="column">
          <Grid2 item>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid2>
          <Grid2 item>
            <TextField
              fullWidth
              label="Password"
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid2>
          <Grid2 item>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Submit
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </Container>
  );
};

export default Login;
